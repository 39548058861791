import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { Angulartics2Module } from 'angulartics2';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), pathMatch: 'full' },
  { path: 'achat/vehicule', loadChildren: () => import('./product/buy.module').then(m => m.BuyModule) },
  { path: 'achat/vehicules', loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule) },
  { path: 'informations', loadChildren: () => import('./static/static.module').then(m => m.StaticModule) },
  { path: 'agences', loadChildren: () => import('./agencies/agencies.module').then(m => m.AgenciesModule) },
  { path: 'agence', loadChildren: () => import('./agency/agency.module').then(m => m.AgencyModule) },
  { path: 'comparateur', loadChildren: () => import('./comparator/comparator.module').then(m => m.ComparatorModule) },
  { path: 'recrutement', loadChildren: () => import('./recruitment/recruitment.module').then(m => m.RecruitmentModule) },
  { path: 'contact', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule) },
  { path: 'reprise', loadChildren: () => import('./trade-in/trade-in.module').then(m => m.TradeInModule) },
  { path: 'service-apres-vente', loadChildren: () => import('./sav/sav.module').then(m => m.SavModule) },
  { path: 'garantie-voiture-neuve-occasion', loadChildren: () => import('./warranty/warranty.module').then(m => m.WarrantyModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'commande-en-ligne', loadChildren: () => import('./online-order/online-order.module').then(m => m.OnlineOrderModule) },
  { path: 'financement', loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule) },
  { path: '**', component: NotFoundComponent }
];

@NgModule({ exports: [RouterModule], imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' }),
        Angulartics2Module.forRoot()], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppRoutingModule { }
