import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { throwIfAlreadyLoaded } from './module-import-guard';

import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxCaptchaModule } from 'ngx-captcha';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faQuestionCircle, faBars, faTimes, faPhone, faHeart as fasHeart, faUser, faCaretDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faXmark, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faHeart as farHeart, faUser as farUser } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faFacebookF, faLinkedinIn, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CoreMenuComponent } from './menu/menu.component';
import { TrackingConsentComponent } from './tracking-consent/tracking-consent.component';
import { InfoPopupComponent } from './info-popup/info-popup.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TrackingCustomConsentComponent } from './tracking-custom-consent/tracking-custom-consent.component';
import { SharedModule } from '../shared/shared.module';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    FontAwesomeModule,
    NgxCaptchaModule,
    MatSnackBarModule,
    MatSlideToggleModule, 
    SharedModule
  ],
  declarations: [
    CoreMenuComponent,
    NavbarComponent,
    FooterComponent,
    TrackingConsentComponent,
    InfoPopupComponent,
    TrackingCustomConsentComponent,
    NotificationBarComponent
  ],
  exports: [
    CoreMenuComponent,
    NavbarComponent,
    FooterComponent,
    TrackingConsentComponent,
    NotificationBarComponent
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule, library: FaIconLibrary) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    library.addIcons(faFacebook, faFacebookF, faLinkedinIn, faInstagram, faTiktok, faYoutube, faQuestionCircle, faCaretDown, faBars, faAngleDown, faTimes, faUser,
      farUser, faPhone, fasHeart, farHeart, faArrowLeft, faXmark, faChevronRight);
  }

}
